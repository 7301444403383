<template>
  <div id="app">
    <router-view
      v-bind:persons="persons"
      v-bind:categories="categories"
      v-bind:personDetail="personDetail"
      @homeClick="cons"
    />
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      persons: null,
      categories: null,
      personDetail: null,
    }
  },
  methods: {
    cons(id) {
      fetch('https://person.lezgikim.ru/api.php?do=getPostById&key=156dfdd335hjkodS&PostID=' + id)
      .then(response => response.json())
      .then(json => {
        this.personDetail = json
      })
    }
  },
  mounted() {
    fetch('https://person.lezgikim.ru/api.php?do=getPostList&key=156dfdd335hjkodS')
    .then(response => response.json())
    .then(json => {
      this.persons = json
    })

    fetch('https://person.lezgikim.ru/api.php?do=get%D0%A1ategories&key=156dfdd335hjkodS')
    .then(response => response.json())
    .then(json => {
      this.categories = json
    })
  }
}
</script>

<style lang="scss">
  *, *:focus {
    box-sizing: border-box;
  }
  body, html {
    padding: 0;
    margin: 0;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .container {
    padding: 0 16px;
  }
  .btn {
    background: none;
    border: none;
  }
  .btn:focus {
    outline: none;
  }
  #app {
    padding-bottom: 70px;
  }
</style>
