<template>
  <div class="home">
    <NavBar/>
    <div class="banner">
      <h1 class="banner__text">Выдающиеся личности Дагестана</h1>
    </div>
    <div class="container">
      <section class="home-section">
        <div class="home-section__top">
          <h2 class="home-section__title">Популярное</h2>
        </div>
        <div class="persons-list">
          <router-link 
            to="/person" 
            class="person" 
            v-for="person in persons"
          >
            <div class="person" @click="$emit('homeClick', person.id)">
              <div class="person__image">
                <img :src="person.img" alt="">
              </div>
              <div class="person__info">
                <span class="person__name">{{person.title}}</span>
                <span class="person__dob">{{person.id}}</span>
              </div>
            </div>
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/NavBar';

export default {
  name: 'Home',
  props: ['persons'],
  data() {
    return {
      personId: 5
    }
  },
  components: {NavBar},
}
</script>

<style lang="scss">
  @import "../assets/css/home.scss";
</style>
